@import url(https://cdnjs.cloudflare.com/ajax/libs/font-awesome/4.6.3/css/font-awesome.css);

$timing: 200ms;

@mixin transformScale($size: 1) {
  transform: scale($size);
  -ms-transform: scale($size);
  -webkit-transform: scale($size);
}
// body {
//   padding: 50px;
// }

.btn {
  background-color: rgba(36, 41, 44, 0.9);
  border: none;
  width: 100%;
  text-align: center;
  /* padding: 14px; */
  border-radius: 4px;
  color: white;
}

.fb {
  background-color: rgb(51, 89, 157);
}

.ui {
  margin-left: 15%;
}

@media screen and (max-width: 600px) {
  .ui {
    margin-left: 5%;
  }
}

.input {
  background: rgba(241, 241, 241, 0.7);
  width: 80%;
  padding: 14px;
  border-radius: 4px;
  border: 0;
  color: #3e3e3e;
}

.bckgrnd-lg {
  background-color: rgba(255, 255, 255, 0.836);
  border-radius: 10px;
  box-shadow: 0 10px 20px rgba(0, 0, 0, 0.19), 0 6px 6px rgba(0, 0, 0, 0.23);
}

.mrgn-jr {
  margin: auto;
}

.pdng-jr {
  padding: 15px 0 35px;
}

.bckgrnd-img-2 {
  /* background-image: url(/img/dfsdfsf.png); */
  background-image: url(../../../assets/tradesbarn/images/login/login.jpg);
  // background-color: #038fde !important;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover !important;
}

.social-container-lgn {
  margin: auto;
}

.social-icons-lgn {
  padding: 0;
  list-style: none;
  margin: 0;

  li {
    display: inline-block;
    margin: 0.15em;
    position: relative;
    font-size: 1.1em;
  }

  svg {
    color: #fff;
    position: absolute;
    top: 11px;
    left: 12px;
    transition: all $timing ease-out;
  }

  a {
    display: inline-block;

    &:before {
      @include transformScale();
      content: " ";
      width: 40px;
      height: 40px;
      border-radius: 100%;
      display: block;
      //background: linear-gradient(45deg, $iconColor, $accent);
      background-image: linear-gradient(
        109.6deg,
        rgba(31, 179, 237, 1) 11.2%,
        rgba(17, 106, 197, 1) 91.1%
      );
      transition: all $timing ease-out;
    }

    &:hover:before {
      transform: scale(0);
      transition: all $timing ease-in;
    }

    &:hover svg {
      @include transformScale(2);
      color: rgba(17, 106, 197, 1);
      //background: -webkit-linear-gradient(45deg, $iconColor, $accent);
      background-image: linear-gradient(
        109.6deg,
        rgba(31, 179, 237, 1) 11.2%,
        rgba(17, 106, 197, 1) 91.1%
      );
      -webkit-background-clip: text;
      -webkit-text-fill-color: transparent;
      transition: all $timing ease-in;
    }
  }
}

.hr-lgn {
  border-top: 1px solid rgb(238, 238, 238);
}

.lgn-clr {
  color: #545454;
}

.frm-mrgn-top {
  margin-top: 42px;
}

.login-logo {
  cursor: pointer;
}

.login-logo img {
  transition: 0.3s;
}
.login-logo img:hover {
  transform: scale(1.2);
  z-index: 10;
}

.log-placehldr {
  .ant-form-item-control-wrapper
    .ant-form-item-control
    .ant-form-item-children
    input::placeholder {
    color: rgb(88, 88, 88);
  }
}
