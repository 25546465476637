.mrgn-catch .ant-form-item {
  margin-bottom: 10px;

  .ant-form-item-control-wrapper
    .ant-form-item-control
    .ant-form-item-children
    input::placeholder {
    color: rgb(88, 88, 88);
  }
}

.term-catch .ant-col .ant-form-item-control .ant-form-item-children {
  line-height: 20px;
}
