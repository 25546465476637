.news--item {
    text-decoration: none;
    color: inherit;
    margin: 1.25rem 0;
    padding-left: 0.75rem;
  }
  /* on hover and when focused slightly decrease the weight of the anchor links */
  .news--item:hover,
  .news--item:focus {
    color: hsl(0, 0%, 35%);
  }
  .news--item .date {
    font-size: 0.8rem;
    font-weight: bold;
  }
  .news--item .brief {
    font-size: 0.9rem;
  }

  .board__news {
    display: flex;
    margin: 1rem 0 3rem;
    flex-direction: column;
    width: 80%;
    justify-self: center;
    /* dictate a maximum height to allow for vertical scroll */
    max-height: 300px;
    overflow-y: auto;
  }
  /* minor style changes for the scrollbar */
  .board__news::-webkit-scrollbar {
    width: 0.25rem;
  }
  .board__news::-webkit-scrollbar-track {
    box-shadow: inset 0 0 6px hsla(200, 100%, 5%, 0.3);
  }
  .board__news::-webkit-scrollbar-thumb {
    background: hsl(200, 100%, 10%);
    border-radius: 5px;
  }

  .news_card{
      padding:0;
      /* padding-left: 10px; */
  }